@import 'assets/scss/app';
@import 'assets/scss/custom';
@import 'antd/dist/antd.css';

.ant-picker-panel-container {
  border-radius: 8px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #6bb870;
  border-radius: 4px 0px 0px 4px;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: rgba(107, 184, 112, 0.14) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #6bb870;
}

.ant-picker-range .ant-picker-active-bar {
  background: #6bb870;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #6bb870;
  box-shadow: none;
}
