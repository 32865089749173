.big_sidebar {

  .psWrapper,
  .psWrapperTop {
    width: calc(100vw - 480px);
  }

  aside {
    padding: 16px;
  }
  .helpcenter_section,
  .create-shift,
  .settings_section,
  .report_section,
  .project_section,
  .task_section,
  .dashboard_section {
    .section_header {
      width: 100%;
      left: auto;
      right: 0;
      transition: .25s;
    }
  }
  .new_theme {
    .new_header {
      width: calc(100% - 244px);
      left: auto;
      right: 0;
      padding: 30px 0 0 0px;
    }
  }

  .notes {
    max-width: 24.5%;
    right: 0;

    @media (max-width: 1499px) {
      max-width: 23.5%;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    .navItemLink {
      margin-top: auto;
    }
  }
}

.nav_bar_dashboard {
  background: #16181D;
}

.small_sidebar {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    .navItemLink {
      margin-top: auto;
    }
  }
  
  .psWrapper,
  .psWrapperTop {
    width: calc(100vw - 315px);
  }

  .selected-user-tooltip {
    width: calc(100% - 160px);
  }
  .navigation {
    height: calc(100vh - 206px);
  
    &.nav_without_hotel {
      height: calc(100vh - 144px);
    }
  }

  // .sidebar_menu_open {
  //   .more_options  {
  //     display: none;
  //   }
  // }
  
  aside {
    padding: 16px 0;
  }
  .helpcenter_section,
  .create-shift,
  .settings_section,
  .report_section,
  .dashboard_section {
    .section_header {
      width: calc(100% - 80px);
      left: auto;
      right: 0;
      transition: .25s;
    }
  }
  .new_theme {
    .new_header {
      width: calc(100% - 80px);
      left: auto;
      right: 0;
      padding: 30px 0 0 0px;
    }
  }

  .navItemLink {
    width: 100%;
    padding: 12px 12px 4px 12px;
    margin: 0;
    
    .user_details {
      justify-content: center;
      // .user_info {
      //   display: none;
      // }
      .user_btn_options {
        right: 0;
        width: 20px;
        top: 7px;
        .expand_icon {
          .ant-image {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .sidebar_location {
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 15px;

    .location_top {
      justify-content: center;
      // .location_name_btn {
      //   display: none;
      // }
    }
    // .location_info {
    //   display: none;
    // }
  }
  .workshift_sidebar {
    width: 25%;
  }

  .create_location_admin {
    margin-left: -90px;
    width: calc(100% + 90px);

    .steps-content {
      width: calc(100% - 90px);
      margin-left: 90px;
    }

    .location_sidebar {
      width: 90px;
      display: flex;
      flex-direction: column;

      h3 {
        display: none;
      }

      .no_bg {
        margin: auto 0 0 0;

        img {
          margin: 0 !important;
        }

        span {
          display: none;
        }
      }
    }
  }
}


.small_sidebar {
  .project_section,
  .task_section {
    .section_header {
      width: 100%;
      left: auto;
      right: 0;
      transition: .25s;
    }
  }

  .new_project .section_header,
  .new_task .section_header {
    padding-left: 80px;
  }
}



.psWrapper {
  position: relative;
  padding-top: 60px;
  padding-bottom: 70px;

  &:hover {
    .scrollNav {
      display: flex
    }
  }
}

.psWrapperTop {
  position: relative;
  padding-top: 60px;
  padding-bottom: 70px;
  &:hover {
    .scrollNav {
      display: flex
    }
  }
}

.scrollNav {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 3px;
  padding: 0 10px;
  pointer-events: none;
  border-top: 1px solid #DBDDDF;
  height: 66px;
  background: #fff;
}

.sidebar_logo {
  padding: 8px 2px;
  display: flex;
  align-items: center;

  .ant-image {
    &:first-child {
      margin-right: 8px;
    }
  }
}

.navigation {
  .topNav {
    .nav_li {
      padding: 7px 16px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      cursor: pointer;

      span {
        color: #9CA0AA; 
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      svg {
        margin-right: 12px;
        path {
          fill: #9CA0AA
        }
      }

      &.active {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 44px;

        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff
          }
        }
      }
    }
  }
}

.ant-layout {
  .ant-layout-sider {
    position: fixed;
    height: 100vh;
    z-index: 10;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
  }

  .content_section {
    padding-left: 80px;
    transition: all 0.2s;
    min-height: 100vh;
  }

  &.big_sidebar {
    .content_section {
      padding-left: 244px;
    }
    .deactivatedHeader {
      left: 244px;
      width: calc(100% - 244px);
    }
  }

  &.small_sidebar {
    .back_to_home {
      margin-left: 0;
      margin-right: 0;
    }
    .deactivatedHeader {
      left: 80px;
      width: calc(100% - 80px);
    }

    // .back_location{
    //   span {
    //     display: none;
    //   }
    // }

    .navigation {
      .bottomNav,
      .topNav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .nav_li {
          width: 36px;
          height: 36px;
          padding: 0;
          justify-content: center;

          svg {
            margin: 0;
          }
        }
      }
    }
    .sidebar_logo {
      justify-content: center;
      .ant-image {
        &:first-child {
          margin-right: 0;
        }
        // &:nth-child(2) {
        //   display: none;
        // }
      }
    }
    // .bottomNav,
    // .topNav {
    //   .nav_label {
    //     display: none;
    //   }
    // }
  }
}

.back_to_home {
  border-bottom: 1px solid #505560;
  margin-left: -16px;
  margin-right: -16px;
  padding: 4px 15px;
  height: 44px;
  margin-top: -15px;
}

.navigation {
  height: calc(100vh - 290px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #1a2d3f;
  }

  &.nav_without_hotel {
    height: calc(100vh - 168px);
  }

  .bottomNav {
    margin-top: auto;

    .nav_li {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;

      span {
        color: #9CA0AA; 
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      svg {
        margin-right: 12px;
        path {
          fill: #9CA0AA
        }
      }

      &.active {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 44px;

        span {
          color: #fff;
        }

        svg {
          path {
            fill: #fff
          }
        }
      }
    }
  }
}

.navItemLink {
  position: relative;
  padding: 18px 16px 0 16px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: auto;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #505560;
    position: absolute;
    top: 0;
    left: 0;
  }

  .user_details {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;

    .user_info {
      margin-left: 8px;
      width: 132px;

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 2px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #9CA0AA;
        margin-bottom: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .user_btn_options {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
  }
}

.expand_icon {
  width: 20px;

  button {
    &.ant-btn {
      width: 20px;
      height: 20px;
      background: #16181D !important;
      border: 1px solid #505560 !important;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 1px solid #fff !important;
      }
    }
    .ant-image {
      display: block;
    }
  }
}

.sidebar_menu {
  width: 240px;
  padding: 16px;

  .user_details {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;

    .user_info {
      margin-left: 6px;
      width: 125px;

      h3 {
        color: #16181D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #9CA0AA;
        margin-bottom: 0;
      }
    }
  }

  &.ant-dropdown-menu {
    .ant-dropdown-menu-item {
      cursor: inherit;
  
      &.ant-dropdown-menu-item-active,
      &:hover {
        background-color: #fff;
      }
    }

    .sidebar_button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      margin-bottom: 4px;
      height: auto;

      .ant-image {
        display: inline-block;
        margin-left: 0;
      }

      .menu_label {
        margin-left: 8px;
        color: #16181D;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.sidebar_location {
  margin-top: 12px;
  margin-bottom: 15px;
  border-bottom: 1px solid #505560;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px 15px;

  .location_top {
    display: flex;
    align-items: center;
  
    .location_name_btn {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: 162px;
  
      .location_label {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        width: 122px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .location_info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9CA0AA;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.location_sidebar_menu {
  h3 {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .location_ul {
    margin-left: -8px;
    margin-right: -8px;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 105px);
  }
  .loc_change_btn {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    text-align: left;
    white-space: pre-wrap;
    padding: 8px;
    width: 100%;

    &.active_location {
      background-color: #ffffff1c !important;
    }
    
    .location_label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 4px;
    }
    .location_address {
      color: #9CA0AA;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .ant-image {
    img {
      transform: rotate(180deg);
    }
  }
}

.sidebar_menu_open {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 7px 3px;
  margin-right: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
  }

  .more_options {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: #fff;
    display: none;
  }
}

.side_dropdown {
  .sidebar_menu {
    .ant-dropdown-menu-title-content {
      width: 100%;
    }
    .user_details {
      .ant-avatar {
        border: 1px solid #505560;
        background: rgba(255, 255, 255, 0.04);
        font-size: 13px !important;
        padding: 0 2px;
        white-space: nowrap;
        overflow: hidden;
        color: #16181D;
    
        .ant-avatar-string {
          transform: scale(0.9) !important;
          position: static !important;
        }
      }

      .user_info {
        width: calc(100% - 50px);
        
        h3 {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &.side_drop_collapsed {
    left: 32px !important;
  }
}

.hide_admin_sidebar {
  .ant-layout-sider {
    display: none;
  }
  .content_section {
    padding-left: 0;
  }
}

.hotel_tooltip {
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #eee
  }

  .ant-tooltip-inner {
    color: #16181D;
    padding: 10px;
    background: #eee;
    font-size: 13px;
  }

  .ant-tooltip-arrow {
    transform: translateY(94%) translateX(-50%);
  }
}