.big_sidebar .dashboard_section .delivery_widget .section_header {
  width: 100%;
  width: 100%;
  position: inherit;
  left: auto;
  right: auto;
  height: auto;
} 

.delivery_service_card {
  .service_not_active {
    background: rgba(250, 141, 105, 0.14);
    border-radius: 16px;
    margin-top: 16px;
    .anticon {
      color: #fa8d69;
    }
    .content {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #53595c;
      background: none;
      margin-bottom: 4px;
    }
    .setting_link {
      .button {
      background: none;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #fa8d69;
      padding: 0;
    }}
    .circle_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
      width: 28px;
      height: 28px;
      background: rgba(250, 141, 105, 0.14);
      border-radius: 43px;
      margin-top: -5px;
    }

    @media (max-width: 1200px) {
      .circle_icon {
        width: 40px;
        height: 20px;
        margin-top: -1px;
      }
    }
  }

  .delivery_service_tabs {
    .ant-tabs-nav {
      margin-bottom: 8px;
      display: contents;
    }
    .ant-spin-nested-loading {
      width: auto;
    }
    .ant-tabs-nav-wrap {
      background: white;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #6bb870;
      .ant-badge-count {
        color: #6bb870;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.003em;
        background: rgba(107, 184, 112, 0.14);
        border-radius: 94px;
        width: 18px;
        height: 18px;
        padding: 2px;
      }
    }
    .ant-tabs-tab:hover {
      color: #6bb870;
    }
    .ant-tabs-ink-bar {
      background: #6bb870;
      border-radius: 6px;
      height: 3px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      // margin: 0 0 0 48px;
      margin: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
    .ant-tabs-tab {
      // margin: 0 0 0 32px;
      padding-left: 12px;
      padding-right: 12px;
      // padding-left: 24px;
      // padding-right: 24px;
      // font-size: 18px;
      padding-top: 15px;
      padding-bottom: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.003em;
      color: #b9bbbd;
      :hover {
        color: #6bb870;
      }
      :focus {
        color: #6bb870;
      }
      .ant-tabs-tab-btn {
        .ant-badge-count {
          color: #b9bbbd;
          font-weight: 700;
          font-size: 10px;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.003em;
          background: #f5f5f5;
          border-radius: 94px;
          width: 18px;
          height: 18px;
          padding: 2px;
        }
      }
    }
    .ant-tabs-content-holder {
      .ant-tabs-content .ant-tabs-content-top {
        .ant-tabs-tabpane {
          display: flex;
        }
      }
    }
    .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
      height: 3px;
    }
  }

  .empty_no_delivery {
    margin: 55px 8px;
    .ant-empty-image {
      height: 48px;
      width: 132px;
      display: contents;
    }
    .ant-empty-description {
      margin-top: 16px;
      // padding: 0 10px;
      display: grid;
    }
    .btn_no_delivery {
      background: transparent;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.006em;
      color: #6bb870;
      padding: 0;
      margin: 11px 0 0 0;
    }
    .empty-heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.003em;
      color: #fa8d69;
      margin-bottom: 12px;
    }
    .empty-paragraph {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.003em;
      color: #7f8385;
    }
  }

  .ant-card-body {
    padding: 12px 24px 20px;
  }

  .section_header {
    position: absolute;
    padding: 0;
    left: 24px;

    .heading {
      // font-family: 'Roboto';
      // font-style: normal;
      // font-weight: 500;
      // font-size: 16px;
      // line-height: 19px;
      // letter-spacing: 0.003em;
      // color: #1B1C1D;
      padding-left: 0;
      .header_heading {
        // font-size: 22px;
        // line-height: 32px;
        // color: #1B1C1D;
        // font-weight: 700;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.003em;
        color: #1b1c1d;
        margin-right: 0;
      }
    }
  }
}

.Task_Card {
  .section_header {
    position: absolute;
    padding: 0;
    left: 24px;
    top: 20px;
    .heading {
      padding-left: 0;
      .header_heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.003em;
        color: #1b1c1d;
        margin-right: 0;
      }
    }
  }
  .taskCardStatus {
    margin-top: 64px;
  }
}

.dashboard-widget {
  .delivery-cards {
    // width: 321px;
    width: auto;
    border: 0;
    // display: flex;
    // justify-content: center;
    > .ant-card-body {
      // padding: 0 !important;
      padding: 0 10px;
      border: 0;
      width: 100%;
      .col-card {
        padding: 0 !important;
      }
    }
    .cards_content {
      // width: 321px !important;
      // padding: 0 !important;
      width: 100%;
      .card_empty {
        // width: 321px;
        width: auto;
        background: #ffffff;
        // padding: 16px;
        padding: 0;
        .ant-card-body {
          padding: 0;
        }
        .empty_no_delivery {
        }
        .btn_no_delivery {
        }
      }
      .card_content {
        // width: 321px;
        width: auto;
        background: #ffffff;
        border: 1px solid #dbdddf;
        border-radius: 10px;
        padding: 16px 16px 8px 16px;
        margin: 16px 0;
        .ant-card-head {
          padding: 0;
          margin: 0 8px 11px 8px;
          min-height: 20px;
          border-bottom: none;
          .ant-card-head-title {
            padding: 0;
          }
        }
        .ant-card-body {
          padding: 0;
        }
        .content_scheduled {
          display: flex;
          justify-content: space-between;
          p {
            // margin-bottom: 8px;
            margin: 0 8px 8px 8px;
          }
        }

        .content_status {
          display: flex;
          justify-content: space-between;
          background: rgba(250, 141, 105, 0.14);
          border-radius: 8px;
          padding: 7px 8px 6px 8px;
          // color: #fa8d69;
          margin-bottom: 8px;

          .status-icon > span {
            margin-right: 16px;
          }
        }
        @media (min-width: 1200px) {
          .content_status {
            font-size: 14px;
            .status-icon > span {
              margin-right: 16px;
            }
          }
          .content_scheduled {
            font-size: 14px;
          }
          .ant-card-head {
            font-size: 16px;
          }
        }

        @media (max-width: 1200px) {
          .content_status {
            font-size: 12px;
            .status-icon > span {
              margin-right: 8px;
            }
          }
          .content_scheduled {
            font-size: 12px;
          }
          .ant-card-head {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.ant-tabs-nav-operations {
  display: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}
