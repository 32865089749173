.ant-upload-drag {
  background: #fbfbfc !important;
  border-radius: 16px !important;
  .ant-upload-drag-icon {
    svg {
      width: 40px;
      height: 40px;
    }
    margin-top: 44px;
  }
  .ant-upload-text {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #16181d !important;
    margin-bottom: 4px;
  }
  .ant-upload-hint {
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    margin-bottom: 44px;
  }
}

.row-btn {
  margin-top: 32px !important;
}
.checklist-btn {
  padding: 8px 16px;
  gap: 8px;
  width: 100%;

  // color: #fff !important;
  height: 36px !important;
  // background: #0B9F57 !important;
  border-radius: 44px !important;
}

.newDailyChecklistForm {
  .ant-col {
    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .line_dropdown .ant-select-selector {
    border-radius: 8px !important;
    border-bottom: none !important;
    border: 1px solid #E4E5E7 !important;

  }

  .ant-upload.ant-upload-drag {
    height: 225px;
  }
}

.ant-upload-list-item-info {
  .ant-upload-text-icon {
    .anticon {
      svg {
        color: black;
      }
    }
    .ant-upload-list-item-name {
      color: black;
    }
  }
  .ant-upload-text-icon {
    color: black;
  }

}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name,.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color:  black;
}
