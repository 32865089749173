.new_theme {
  padding-top: 98px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  .new_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px 0 0 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #fff;

    .header_heading {
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      color: #16181d;
      padding-left: 40px;
    }
  }

  .ant-table-container table {
    > .ant-table-thead {
      > tr {
        th {
          color: #9ca0aa;
          padding: 20px 8px 20px;

          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }

    > .ant-table-tbody {
      > tr {
        td {
          padding: 8px 8px 8px;
          color: #16181d;

          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.new_date_filter {
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  width: 186px;
  height: 36px;
  padding: 8px 12px;
  display: flex;
  align-items: center;

  .ant-image-img {
    display: block;
  }

  .ant-image {
    margin-right: 6px;
  }

  .ant-picker {
    border: 0;
    padding: 0;
    margin-left: 5px;

    .ant-picker-suffix {
      display: none;
    }
  }
}

.new_form {
  .count_input {
    .hint-label {
      color: #505560;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding-top: 5px;
    }
    .ant-input-affix-wrapper {
      &.formControl {
        padding: 0 !important;
        border: 0;

        input {
          width: 100%;
        }
    
        .ant-input-suffix {
          position: absolute;
          right: 0;
          bottom: -20px;
          margin-left: 0;
          color: #505560;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  .ant-form-item {
    color: #16181d;
    margin-bottom: 12px;

    .ant-row {
      flex-direction: column;
    }

    .ant-form-item-label {
      justify-content: flex-start;
      margin-bottom: 4px;
      padding-bottom: 0;

      > label {
        color: #16181d;
        font-weight: 400;
        line-height: 20px;
        height: auto;
        &:after {
          display: none;
        }
      }
    }

    .formControl {
      border: 1px solid #e4e5e7;
      border-radius: 8px;
      background: #ffffff;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 12px !important;
    }

    .ant-input-number-handler-wrap {
      border-radius: 0 8px 8px 0;
    }

    textarea {
      resize: none;
    }

    .ant-select {
      .ant-select-selector {
        border: 1px solid #e4e5e7;
        border-radius: 8px;
        background: #ffffff;
        height: 36px;
        padding: 0 12px;

        .ant-select-selection-search-input {
          height: 34px;
          padding: 0 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .new_radio {
    .ant-radio-group {
      width: 100%;

      label {
        width: 50%;
        border: 2px solid #f7f8f9;
        background: #f7f8f9;
        height: 36px;
        text-align: center;
        color: #16181d;
        font-weight: 400;
        font-size: 14px;

        &:first-child {
          border-radius: 44px 0 0 44px;
          border-right: inherit;
        }

        &:last-child {
          border-radius: 0 44px 44px 0;
        }

        &:before {
          display: none !important;
        }

        span {
          &.ant-radio-button-inner,
          &.ant-radio-button {
            &:before {
              display: none !important;
            }
          }
          &:before {
            content: '';
            background: #0b9f57;
            border-radius: 100px;
            width: 9px;
            height: 9px;
            display: none;
            margin-right: 8px;
          }
        }

        &.ant-radio-button-wrapper-checked {
          box-shadow: none !important;
          &:after {
            content: '';
            width: 100%;
            background: #fff;
            border-radius: 44px;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 100%;
          }

          span {
            position: relative;
            z-index: 9;

            &:before {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .ant-select-arrow {
    z-index: 9;
  }

  &.ant-form-vertical {
    .ant-form-item {
      margin-bottom: 24px;
    }
  }

  .ant-input-number-input {
    height: auto;
    padding: 0;
  }
}

.center_content {
  width: 100%;
  height: calc(100vh - 192px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #fbfbfc;
  border-radius: 12px;

  .checklist_icon {
    margin-bottom: 32px;
  }

  .checklist_heading {
    margin-bottom: 16px;
  }

  .checklist_paragraph {
    margin-bottom: 48px;
  }
}

.edge_card {
  border: 0;
  margin: 0 -32px 32px;
  background: #f7f8f9;
  border-radius: 0;
  width: calc(100% + 64px);
  margin-bottom: 24px;

  .ant-typography {
    text-transform: capitalize;
  }

  .ant-card-body {
    padding: 32px;
  }

  .ant-select-arrow {
    z-index: 9;
  }
}

.checklist_assignee {
  background: #f7f8f9;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px;

  .items {
    height: 32px;
    color: #16181d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px;
    cursor: pointer;
    white-space: nowrap;

    .ant-avatar {
      background: transparent;
    }

    &.active_assignee {
      svg {
        path {
          fill: #0b9f57;
        }
      }
    }
  }

  .active_assignee {
    background: #ffffff;
    border-radius: 16px;
  }

  .by_asignee {
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;

    span {
      color: #e4e5e7;
      margin-right: 21px;
    }
  }

  .item_box {
    margin-right: 22px;
    padding: 4px;
    cursor: pointer;
    white-space: nowrap;

    .name {
      padding-left: 4px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .assignee_items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .item_box {
    display: flex;
    align-items: center;
  }
}

.checklist_filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  align-items: flex-start;

  .checklist_filters_left {
    display: flex;
    align-items: flex-start;
  }
  .checklist_filters_right {
    align-items: center;
    display: flex;

    .filter_btns {
      margin-left: 8px;
    }
  }

  .role_dropdown {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    height: 36px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    color: #505560;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 250px;
    margin-right: 8px;
    position: relative;

    .ant-select {
      padding-left: 4px;
      width: 194px;
    }

    .ant-select-arrow {
      right: 0;
    }

    .shift_tag {
      display: flex;
      background: #f2fcf7;
      border-radius: 3px;
      color: #0b9f57;
      white-space: nowrap;
      text-transform: capitalize;
      padding: 1px 5px;
      font-weight: 500;
      position: absolute;
      left: 107px;
      font-size: 12px;
    }
  }
  .filter_dropdown {
    .ant-select-selector {
      height: 20px;
      border: 0;
      padding: 0;

      .ant-select-selection-search-input {
        height: 20px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 20px;
        font-weight: 500;
        color: #16181d;
        font-size: 14px;
      }
    }
  }
  .checklist_dropdown {
    .ant-select-selector {
      .ant-select-selection-item {
        padding-top: 1px;
        color: #0b9f57;
        font-size: 12px;
      }
    }
  }
}

.icon_btn {
  padding: 8px 20px 8px 16px;
  border: 1px solid #e4e5e7 !important;
  border-radius: 44px;
  background: #fff;
  color: #16181d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  &:focus,
  &:hover {
    border: 1px solid #d5d7db !important;
    color: #16181d;
  }

  &.black_btn {
    background: #16181d;
    border: 1px solid #16181d !important;
    color: #fff;

    &:focus,
    &:hover {
      background: #25282f;
      border: 1px solid #25282f !important;
      color: #fff;
    }
  }

  &.green_btn {
    background: #0b9f57;
    border: 1px solid #0b9f57 !important;
    color: #fff;

    &:focus,
    &:hover {
      background: #018545;
      border: 1px solid #018545 !important;
      color: #fff;
    }
  }

  &.pink_btn {
    background: #e04369;
    border: 1px solid #e04369 !important;
    color: #fff;

    &:focus,
    &:hover {
      background: #cf3258;
      border: 1px solid #cf3258 !important;
      color: #fff;
    }
  }

  > .anticon {
    font-size: inherit !important;
    svg {
      display: block;
    }
    + span {
      font-size: inherit !important;
    }
  }
}

.role_multi {
  display: flex;
  flex-wrap: wrap;

  .ant-select-arrow {
    transition: .5s;
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg)
    }
  }
}

.w-100 {
  width: 100% !important;
}

.item_index {
  background: #f7f8f9;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #16181d;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
}

.newTabs {
  .ant-table-container table > .ant-table-thead > tr th,
  .ant-table-container table > .ant-table-tbody > tr td {
    padding: 6px 8px 5px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    background: #f7f8f9;
    border: 2px solid #f7f8f9 !important;
    border-radius: 44px !important;

    .ant-tabs-tab {
      width: 50%;
      border: none !important;
      background: transparent;
      height: 36px;
      text-align: center;
      color: #16181d;
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      margin: 0 !important;

      // &:first-child {
      //   border-radius: 44px 0 0 44px !important;
      //   border-right: inherit;
      // }

      // &:nth-child(2) {
      //   border-radius: 0 44px 44px 0 !important;
      // }

      &:before {
        display: none !important;
      }

      .ant-tabs-ink-bar {
        display: none !important;
      }

      .ant-tabs-tab-btn {
        color: #16181d;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &:before {
          content: '';
          background: #0b9f57;
          border-radius: 100px;
          width: 9px;
          height: 9px;
          display: none;
          margin-right: 8px;
        }
      }

      &.ant-tabs-tab-active {
        box-shadow: none !important;
        &:after {
          content: '';
          width: 100%;
          background: #fff;
          border-radius: 44px;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          height: 100%;
        }

        .ant-tabs-tab-btn {
          position: relative;
          z-index: 9;

          &:before {
            display: inline-block;
          }
        }
      }
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-nav {
    &:before {
      display: none !important;
    }
  }
}

.new_meta {
  .ant-card-meta-detail {
    margin-bottom: 24px;

    .ant-card-meta-title {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #9ca0aa;
    }

    .ant-card-meta-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #16181d;
    }
  }
  .ant-card-meta-avatar {
    padding-right: 0;
  }
  &.meta_completed_by {
    .ant-card-meta-detail {
      overflow: visible;
      .ant-card-meta-title {
        margin-left: -36px;
      }
    }
    .ant-card-meta-avatar {
      padding-right: 8px;
      margin-top: 25px;
      height: 30px;
    }
  }
}

.ant-modal-body {
  .ant-select-arrow {
    z-index: 9;
  }
}

.custom_timepicker {
  .ant-picker-input {
    flex-direction: row-reverse;

    input {
      padding-left: 10px;
    }
  }
}

.ant-btn {
  &.circle_btn {
    border: 1px solid #e4e5e7 !important;
    border-radius: 44px;
    width: 36px;
    height: 36px;
    background: #ffffff !important;
    padding: 0;

    &:hover {
      border: 1px solid #d5d7db;
    }

    .anticon {
      svg {
        color: #16181d;
      }
    }
  }
}

.edit_filter {
  position: relative;
}

// .reset_dropdown {
//     width: 304px;
//     border: 1px solid #E4E5E7;
//     border-radius: 8px;
//     background: #FFFFFF;
//     padding: 0 8px;
//     position: absolute;
//     top: 40px;
//     right: 0;

//     .reset_function {
//         .reset_edit {
//             padding: 8px 12px;
//             border-bottom: 1px solid #E4E5E7;
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//         }
//         .reset_delete {
//             button {
//                 padding: 8px 12px;
//                 display: flex;
//                 align-items: center;
//                 color: #16181D;
//                 font-weight: 400;
//                 font-size: 14px;
//                 line-height: 20px;
//                 width: 100%;
//             }
//         }
//         .reset_time {
//             color: #505560;
//             font-weight: 400;
//             font-size: 14px;
//             line-height: 20px;

//             span {
//                 color: #16181D;
//             }
//         }
//     }

//     .form_reset {
//         padding: 16px 12px;
//     }
// }

.ant-tag {
  width: 107px;
  height: 18px;
  background: #9ca0aa;
  border-radius: 24px;
  padding: 1px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  border: 0;
  margin: 0;

  &.completed {
    background: #0b9f57;
  }

  &.inProgress,
  &.in_progress {
    background-color: #392ec2;
  }
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #0b9f57 !important;
}

.sop_doc {
  position: relative;
  padding-bottom: 1px;
  margin-left: 8px;

  .sop_dropdown {
    padding: 8px 16px;
    width: 150px;
    height: 36px;
    border: 1px solid #e4e5e7;
    border-radius: 44px;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 44px !important;
    }
  }

  span {
    color: #16181d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }

  .ant-avatar {
    background: transparent;
    margin-left: auto;
  }

  .sop_list {
    width: 300px;
    border: 1px solid #e4e5e7;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 40px;

    ul {
      list-style-type: none;
      padding: 8px;
      margin: 0;

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
          border-top: 1px solid #e4e5e7;
          padding-top: 8px;
        }
      }
    }

    button {
      width: 100%;
      padding: 8px;
      background: transparent;
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 14px !important;
        line-height: 20px;
        color: #16181d;

        &.anticon {
          font-size: 20px !important;
        }
      }
    }
  }
}

.sop_upload_icon {
  display: flex;
  align-items: center;
  color: #0b9f57;
  margin-top: 10px;

  .ant-avatar {
    background: #6bb870;
    color: #fff;
    margin-right: 10px;
  }
}

.individual_chip {
  background: #ffffff;
  border-radius: 24px;
  padding: 2px 12px 2px 2px;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #16181d;

  .ant-image {
    margin-right: 8px;
  }
}

.common_assignees {
  display: flex;
  flex-wrap: wrap;

  .assignees_childs {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 2px 12px 2px 2px;
    background: #ffffff;
    border-radius: 16px;
  }
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1001;
}

.ant-dropdown-menu {
  &.reset_edit {
    padding: 8px;
    border-radius: 8px;
    width: 280px;

    li {
      padding: 0 12px;
      height: 36px;

      &:first-child {
        flex-flow: row-reverse;

        .anticon {
          margin: 0;
        }
      }

      &.ant-dropdown-menu-item-divider {
        height: 1px;
        margin: 8px 0;
      }

      button {
        &.ant-btn {
          padding: 0;
          margin: 0;
          min-width: 36px;
          height: 36px;
        }
      }

      svg {
        width: 36px;
        height: 36px;
      }

      .reset_time {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #505560;

        span {
          color: #16181d;
        }
      }

      .delete_checklist {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.ant-tooltip-placement-topLeft {
  max-width: 400px;
}

.drag-div-name {
  display: contents;
}

.radio_color {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3px;

    .radio_color_box {
      width: 36px;
      height: 36px;
      margin: 3px;
      border-radius: 8px;

      label {
        padding: 0;
        width: 36px;
        height: 36px;
        background: transparent;
        border: 0;
        border-radius: 8px;

        &.ant-radio-button-wrapper-checked {
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-radius: 8px;

          &:after {
            content: '';
            display: block;
            box-sizing: border-box;
            position: absolute;
            left: 10px;
            top: 4px;
            width: 6px;
            height: 12px;
            border-width: 0 2px 2px 0;
            border-style: solid;
            transform-origin: bottom left;
            transform: rotate(45deg);
            color: #fff;
          }
        }

        span {
          display: none;

          &.ant-radio-button {
            display: block;
          }
        }
      }
    }
  }
}

.checklist-row-dragging {
  .drag-visible-icon {
    background: #fafafa !important;
  }
  .drag-individual-name {
    width: 772px;
  }
  .drag-start-time {
    width: 130px;
  }
  .drag-due-time {
    width: 130px;
  }
  .drag-assigned {
    width: 120px;
  }
  .drag-action {
    width: 50px;
  }
}
