.ant-drawer {
  &.custom_footer {
    .ant-drawer-body {
      padding: 0 28px 120px;
    }
  }
  .ant-drawer-header {
    padding: 12px 20px 12px 28px;
    border-bottom: 0;

    @media (max-width: 1499px) {
        padding: 8px 20px 8px 28px;
    }
  }
  .ant-drawer-body {
    padding: 0 32px 32px;
  }
  .ant-drawer-footer {
    padding: 24px 32px;
  }

  .ant-drawer-header-title {
    flex-flow: row-reverse;
  }

  .ant-drawer-title {
    color: #16181d;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .ant-drawer-close {
    padding: 0;
    margin: 0;
  }

  &.dashboard_sidebar {
    &.before_error {
      .ant-spin-nested-loading {
        height: 98%;

        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .ant-drawer-body {
      padding-bottom: 92px;
    }
    .choose_method {
      margin-top: 8px;

      h4 {
        font-size: 20px;
        margin-bottom: 14px;
      }

      .sidebar_text {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }

    .card_choose {
      margin-top: 42px;

      .ant-card {
        background: #ffffff;
        border: 1px solid #e4e5e7;
        border-radius: 12px;
        cursor: pointer;

        .ant-card-body {
          padding: 20px;
        }

        h4 {
          margin-top: 36px;
          color: #16181d;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }

    .ant-upload.ant-upload-drag {
      border: 1px solid #fbfbfc;

      &:hover {
        border: 1px solid #e4e5e7;
      }
    }

    @media (max-width: 1499px) {
      .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}

.drawer_footer {
  position: fixed;
  width: 520px;
  right: 0;
  bottom: 0;
  border-top: 1px solid #e4e5e7;
  padding: 24px 32px;
  background-color: #fff;
  z-index: 9;
  @media (max-width: 1499px) {
    padding: 18px 32px;
  }
}

.drawer_note {
  width: 456px;
  margin-top: auto;

  .info_card {
    background: #f0effb;
    border-radius: 10px;

    .ant-card-body {
      padding: 12px 16px 12px 16px !important;
    }

    .info_text .ant-typography {
      color: #16181d;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      @media (max-width: 1499px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

.download_csv {
  color: #0b9f57;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;

  .ant-image {
    margin-right: 4px;
  }

  &:hover {
    color: #0b9f57;
  }
}

.drawer_error_note {
  margin-top: 24px;
  .info_card {
    background: #fdeff3;
    margin-bottom: 20px;
    border: 0;
  }
}

.error_table {
  .error_head {
    display: flex;
    color: #9ca0aa;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .head_50 {
    width: 50%;
    padding: 0 0 0 14px;
  }
  .error_text {
    display: flex;
    background: #fbfbfc;
    border-radius: 10px;
    margin-bottom: 8px;

    .error_text_50 {
      width: 50%;
      padding: 16px 0 16px 14px;

      &:last-child {
        padding-right: 16 + px;
      }
    }

    .error_name {
      color: #16181d;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .error_role {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #686d79;
      margin-bottom: 8px;
    }

    .error_date_time {
      display: flex;
      align-items: center;

      .error_dt {
        background: #f2f3f7;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 4px;
        color: #16181d;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        height: 20px;

        span {
          white-space: nowrap;
        }

        .ant-image {
          margin-right: 4px;
          img {
            display: block;
          }
        }
      }

      .error_date {
        margin-right: 6px;
      }
    }

    .text_message {
      color: #16181d;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
