@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-virtualized/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './components/slick';

.ps__rail-x {
  z-index: 999;
}

.writeYourOwnClasHere {
  .MuiPaper-root-6 {
    border-radius: 20px;
  }
}

.d-none {
  display: none !important;
}

.MuiDrawer-paper {
  z-index: 1000 !important;
}

.line_dropdown {
  &:hover {
    + .clear_btn {
      opacity: 1;
    }
  }
}

.clear_btn {
  position: absolute;
  top: 34px;
  right: 57px;
  padding: 0 !important;
  width: 12px !important;
  height: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100% !important;
  
  .ant-select-clear {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin: 0;
    opacity: 1;
  }

  &.ant-btn .anticon {
    font-size: 12px !important;
  }
}

.ant-select-clear {
  z-index: 9;
}

.custom-toast {
  &.Toastify__toast-container {
    width: auto !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .Toastify__toast {
      background-color: #53595cf2 !important;
      border-radius: 10px;
      font-weight: 400;
      font-size: 13px;
      color: #fff !important;
      min-height: 50px;
      line-height: 1;
    }

    .Toastify__toast-body {
      padding: 0 16px;
      letter-spacing: 0.35px;
    }

    .Toastify__progress-bar {
      background-color: #53595cf2 !important;
    }
  }
}

.translate_dropdown {
  &.ant-select-multiple {
      .ant-select-selector {
          padding-right: 30px !important;
          height: 34px;
          box-shadow: none !important;
          z-index: 9;
          cursor: pointer !important;
          .ant-select-selection-search {
              margin-inline-start: 0;
          }
      }
      .ant-select-selection-overflow {
          flex-wrap: nowrap;
          overflow: hidden;
      }
      .ant-select-selection-item {
          background: #F5F5F5;
          border: 0;
          border-radius: 4px;
      }
      .ant-select-selection-placeholder {
        left: 2px;
      }
  }
  .ant-select-selector {
      background-color: transparent !important;
      border-radius: 0 !important;
      border: 0 !important;
      border-bottom: 1px solid #DBDDDF !important;
      padding: 1px 12px 1px 0 !important;
      overflow: hidden;
      min-height: 34px !important;
      height: auto !important;
      .ant-select-selection-placeholder {
          color: #7F8385;
      }
  }
}

.nowrap_td {
  white-space: nowrap;
}