.dailyChecklist_section {
  .ant-tabs-top {
    margin-bottom: -31px !important;
    width: 100%;
  }
  .ant-tabs-tab {
    font-size: 18px !important;
    left: 8px !important;
    margin-left: 48px !important;
    &:hover {
      color: #6bb870 !important;
    }
    .ant-tabs-tab-btn {
      &:active {
        color: #6bb870 !important;
      }
      &:focus {
        color: #6bb870 !important;
      }
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-size: 18px !important;
      color: #6bb870 !important;
      text-align: center;
      letter-spacing: 0.003em;
      &:active {
        color: #6bb870 !important;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
    background: #6bb870 !important;
    border-radius: 5px 5px 0px 0px !important;
  }

  .ant-row-middle {
    margin-bottom: 17px;
  }
  .ant-card {
    width: 0px;
    margin-bottom: -400px;
    margin-left: 17px;
  }
  .drag-visible {
    .anticon {
      svg {
        width: 9px;
        height: 9px;
      }
    }
  }

  .card-Option {
    right: 0px;
    .anticon {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.rangeDate {
  background: rgba(94, 149, 231, 0.14);
  border-radius: 50px;
  padding: 8px 16px;
  color: #5e95e7;
  font-size: 16px;
}

.newDailyChecklistForm {
  .ant-row-center {
    margin-top: 24px;
  }
  .checklistTime {
    margin-top: 24px;
    margin-bottom: 0;
    .ant-form-item-label {
      float: right;
      width: 90%;
      margin-left: 10%;
    }
  }
  .ant-form-item-control-input-content {
    .select_prefix {
      float: left;
      width: 10%;
      .ant-image {
        top: -10px;
        width: 24px;
      }
    }
  }
  .ant-form-item {
    .formControl {
      float: right;
      width: 90%;
    }
  }
}

.checklistTabs {
  .ant-spin-nested-loading {
    width: auto;
  }
  .ant-tabs-nav-wrap {
    background: white;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6bb870;
  }
  .ant-tabs-tab:hover {
    color: #6bb870;
  }
  .ant-tabs-ink-bar {
    background: #6bb870;
    height: 4px;
    border-radius: 5px 5px 0px 0px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
  .ant-tabs-tab {
    margin: 0 0 0 32px;
    font-size: 18px;
    padding: 25px 24px;
    line-height: 22px;
    :hover {
      color: #6bb870;
    }
    :focus {
      color: #6bb870;
    }
  }
  .ant-tabs-content-holder {
    .ant-tabs-content .ant-tabs-content-top {
      .ant-tabs-tabpane {
        display: flex;
      }
    }
  }
  .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }
}

.Checklist-Cards {
  margin-left: 16px;
  display: inline-block;
  border: 0;

  .editDisable {
    .ant-table-container {
      table {
        > .ant-table-tbody {
          > tr:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  .ant-table-empty {
    padding-bottom: 28px;

    tr {
      text-align: center;

      td {
        padding: 0 !important;
      }
    }
  }

  .ant-card-body {
    padding-top: 18px;
    padding-right: 12px;
    padding: 18px 16px 22px 16px;
    border: 0;
  }

  .ant-table-tbody {
    tr {
      opacity: none;
      visibility: visible !important;
    }
  }

  .ant-card-body .Checklist-Cards_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1b1c1d;
  }

  .ant-card-body .cardOption {
    height: 48px;
    left: 125px;
    width: 100%;
    .ant-btn .ant-btn-text {
      width: 40px;
    }
  }
  .ant-card-body .cardOption_isEdit {
    display: inline-flex;
    column-gap: 8px;

    .ant-btn {
      padding: 11px 16px 11px 13px !important;
      gap: 8px;
      border-radius: 7px;
      font-size: 14px !important;
      height: 40px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0;
  }
  .addTask {
    padding: 9px 12px 9px 8px !important;
    background: rgba(185, 187, 189, 0.22);
    border-radius: 6px;
    gap: 6px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 18px;

    span {
      font-size: 13px !important;

      &.anticon {
        font-size: small !important;
      }
    }
  }
}

.Checklist_popupCard {
  .ant-modal-content .ant-modal-body {
    padding-left: 24px;
    padding-top: 6px;
    padding-right: 7px;
  }
  .check_paragraph {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

.ant-btn {
  .anticon .anticon-plus {
    font-size: none;
    width: 14px;
    height: 14px;
  }
}

.checklistTabs {
  .ant-tabs-tabpane-active {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ant-table-container {
    table {
      > .ant-table-tbody {
        > tr {
          td {
            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .checklist-heading {
    color: #1b1c1d;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .checklist-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #7f8385;
    width: 100%;
  }

  .checklist-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #7f8385;
  }
}

.checklist_add_task {
  &.add_form {
    .ant-form-item {
      padding-left: 40px !important;
      padding-right: 10px;
    }
  }
}

.checklist_table {
  padding-right: 12px;

  .ant-table-container {
    table {
      > .ant-table-tbody {
        > tr {
          td {
            padding-top: 16px;
            padding-bottom: 16px;

            &.ant-table-cell.drag-visible {
              vertical-align: top;
              padding-top: 0;
              padding-bottom: 2px;
            }
          }
        }
      }
    }
  }
  .drag-inner-header {
    width: 370px;
    :hover {
      background: rgba(185, 187, 189, 0.14);
      border-radius: 6px;
    }
    :hover .right_btn {
      opacity: 1;
      visibility: visible;
    }
    .right_btn {
      opacity: 0;
      visibility: hidden;
      background: none;
    }
    .drag-col {
      display: inline-flex;
      width: 370px;

      .drag-row-icon {
        background: none;
        width: 34px;
      }
      .drag-row-item {
        background: none;
        width: 260px;
        align-content: center;
        margin-left: 0;
        padding: 5px 0;
        .drag-card-item-text {
          width: 250px;
          background: none;
          text-transform: capitalize;
          font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.003em;
          // margin-top: 5px;
          // margin-bottom: 5px;

        }
        .drag-card-item-time {
          width: 125px;
          font-size: 12px;
          color: #7f8385;
          line-height: 17px;
          font-weight: 400;
          letter-spacing: 0.003em;
          background: none;
        }
        .drag-card-item-description {
          width: 250px;
          font-size: 12px;
          background: none;
          line-height: 17px;
          font-weight: 400;
          letter-spacing: 0.003em;
          color: #7f8385;
          
          // margin: -5px 0;
        }
        .m-5{
          // margin-top: -5px;
        }
      }
      .drag-icon {
        background: none;
        margin-left: 10px;
        .holderIcon {
          background: none;
          svg {
            background: none;
          }
        }
      }
    }
  }
}

.checklist-row-dragging {
  background: #fafafa;
}

.checklist-row-dragging td {
  vertical-align: top;
  padding-top: 0;
  padding-bottom: 2px;
  .drag-inner-header {
    width: 370px;
    // :hover {
      background: rgba(185, 187, 189, 0.14);
      border-radius: 6px;
    // }
    :hover .right_btn {
      opacity: 1;
      visibility: visible;
    }
    .right_btn {
      opacity: 0;
      visibility: hidden;
      background: none;
    }
    .drag-col {
      display: inline-flex;
      width: 370px;

      .drag-row-icon {
        background: none;
        width: 34px;
      }
      .drag-row-item {
        background: none;
        width: 260px;
        align-content: center;
        margin-left: 0;
        padding: 5px 0;

        .drag-card-item-text {
          width: 250px;
          background: none;
          text-transform: capitalize;
          // margin-top: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.003em;
          
        }
        .drag-card-item-time {
          width: 125px;
          font-size: 12px;
          color: #7f8385;
          background: none;
          line-height: 17px;
          font-weight: 400;
          letter-spacing: 0.003em;
        }
        .drag-card-item-description {
          width: 250px;
          font-size: 12px;
          background: none;
          color: #7f8385;
          line-height: 17px;
          font-weight: 400;
          letter-spacing: 0.003em;
          // margin: -5px 0;
        }
        .m-5{
          // margin-top: -1.5px;
        }
      }
      .drag-icon {
        background: none;
        margin-left: 10px;
        .holderIcon {
          background: none;
          svg {
            background: none;
          }
        }
      }
    }
  }
}

.checklist-row-dragging .drag-visible {
  visibility: visible;

  .task-cards {
    background: #6bb870;
    .ant-row {
      .ant-col:first-child {
        width: 250px;
      }
      .ant-col:last-child {
        width: 68px;
      }
    }
  }
}
