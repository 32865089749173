.task_section {
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 64px;
  min-height: 100vh;
}

.new_task {
  min-height: 100vh;

  &.task_detail {
    .ant-card-body {
      min-height: auto;
    }
  }

  .ant-card {
    margin: 16px;
  }

  .ant-card-body {
    padding: 0;
    min-height: 500px;
  }

  .add_form {
    >.ant-row {
      padding: 0 24px 24px;
    }
  }

  .section_header {
    position: fixed;
    padding-left: 250px;
    z-index: 9;
  }
}

.tasks_card {
  padding: 16px;
  width: 100%;

  .ant-collapse-content {
    >.ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .ant-tag {
    &.table_tag {
      background-color: #b9bbbd;
      color: #fff;
      padding: 6px 0;
      border-radius: 50px;
      line-height: 1;
      margin: 0;
      width: 105px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      border: 0;
      box-shadow: none;
      height: auto;
    }

    &.inProgress {
      background-color: #5e95e7;
    }

    &.notCompleted {
      background-color: #b9bbbd;
    }

    &.completed {
      background-color: $green;
    }
  }
}

.task_top_select {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 73px;
  line-height: 1;
  border-bottom: 2px solid #dbdddf;

  &:hover {
    border-color: #b9bbbd;
  }

  &.active_dropdown {
    border-color: $green;
  }

  .chips_btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;

    .custom_task_select {
      width: 100%;
      padding: 25px 24px;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: #b9bbbd;
      cursor: pointer;
      height: 100%;
    }
  }
}

.request_body {
  position: relative;
  margin-bottom: 32px;

  &.error_request {
    padding-bottom: 20px;

    .task_top_select {
      border-color: $red;
    }

    .request_error_text {
      color: $red;
      position: absolute;
      bottom: 0;
      left: 24px;
    }
  }
}

.no_result {
  padding: 20px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1c1d;
}

.dropdown_request {
  max-width: 787px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(27, 28, 29, 0.12), 0px 0px 8px rgba(27, 28, 29, 0.04);
  border-radius: 12px;
  position: absolute;
  top: 68px;
  left: 24px;
  width: 100%;
  z-index: 99;

  .dropdown_group {
    border-bottom: 1px solid #dbdddf;
    padding: 12px 0;

    &:last-child {
      border-bottom: 0;
    }

    ul {
      padding: 0 0;
      list-style-type: none;
      margin-bottom: 0;
    }
  }

  .group_name {
    color: #5e95e7;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 12px 6px 12px;
    line-height: 17px;

    &.popular_group {
      color: #8578d7;
    }
  }

  .active_list {
    .dropdown_list {
      background: rgba(185, 187, 189, 0.14);
      cursor: no-drop;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .dropdown_list {
    color: #1b1c1d;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 5px 12px 6px 12px;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    &:hover {
      background: rgba(185, 187, 189, 0.14);
    }
  }
}

.left_icon {
  .ant-input-number-group-wrapper {
    border: 0 !important;

    .ant-input-number-input {
      padding: 0;
    }
  }

  .ant-input-number-group-addon {
    border: 0;
    background: transparent;
    color: #7f8385 !important;
  }

  .ant-row {
    flex-direction: column;
  }

  .ant-form-item-label {
    justify-content: flex-start;

    label {
      height: 18px;
      color: #7f8385;
      font-size: 12px;

      &:after {
        display: none;
      }
    }
  }

  .ant-input {
    background: transparent;
  }
}

.add_form {
  .ant-form-item {
    padding-left: 50px !important;
    padding-right: 40px;
    margin-bottom: 0;

    @media (max-width: 1390px) {
      padding-left: 40px;
      padding-right: 30px;
    }
  }

  .select_prefix,
  .ant-input-prefix {
    position: absolute;
    left: -40px;
    top: 0;
    z-index: 1;
    width: 44px;
    height: 42px;
    display: flex;
    align-items: start;
  }

  .custom_dropdown {
    &.ant-select-multiple {
      .ant-select-selection-item {
        background-color: #f5f5f5;
      }
    }
  }

  .ant-picker-input {
    height: 43px;
  }
}

.tasks_radio {
  margin-top: 3px;

  &.freq_pop {
    margin-top: 14px;
  }

  .ant-radio-group {
    max-width: 380px;
    display: flex;
    border-radius: 8px;
    flex-wrap: nowrap;
    flex-direction: row;

    .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
      box-shadow: none !important;
      color: #7f8385;
      font-size: 14px;
      font-weight: 400;
      text-shadow: none !important;
      line-height: 37px;
      height: 38px;
      margin: 0;
      white-space: nowrap;

      &::before {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        border-color: #6bb870 !important;
      }

      &:first-child {
        border: 1px solid #dbdddf;
        border-radius: 8px 0 0 8px;
        border-right: 0;

        :hover {
          border-color: #6bb870 !important;
        }
      }

      &:last-child {
        border: 1px solid #dbdddf;
        border-radius: 0 8px 8px 0;
        border-left: 0;

        :hover {
          border-color: #6bb870 !important;
        }
      }

      &.ant-radio-button-wrapper-checked {
        color: #fff;

        &:after {
          content: '';
          background: $green;
          position: absolute;
          top: -1px;
          left: -1px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border-radius: 8px;
          z-index: -1;
        }

        &:hover {
          border-color: #6bb870 !important;
        }

        &:first-child {
          border-right: 0;
        }

        &:last-child {
          border-left: 0;
        }
      }
    }

    &.ant-radio-button-wrapper-checked {
      color: #fff;

      &:after {
        content: '';
        background: $green;
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 8px;
        z-index: -1;
      }

      &:hover {
        border-color: #6bb870 !important;
      }

      &:first-child {
        border-right: 0;
      }

      &:last-child {
        border-left: 0;
      }
    }

    // umberRadio: {
    //     display: 'flex',
    //     marginBottom: 32,
    //     // border: '1px solid #DBDDDF',
    //     borderRadius: '8px',
    //     flexDirection: 'row !important' as any,
    //     flexWrap: 'nowrap !important' as any,

    //     '& .MuiButtonBase-root': {
    //       position: 'absolute',
    //       width: '100%',
    //       borderRadius: '0',
    //       padding: '0',
    //       height: '100%',
    //       border: '1px solid #DBDDDF',

    //       '& .MuiIconButton-label': {
    //         opacity: '0',
    //         visibility: 'hidden',
    //       },

    //       '&.Mui-checked': {
    //         background: '#6BB870',
    //         border: '1px solid #6BB870',
    //         borderRadius: '8px',

    //         '&~.MuiTypography-root': {
    //           color: '#fff',
    //         },
    //       },
    //     },

    //     '& .MuiTypography-root': {
    //       fontSize: '14px',
    //       width: '100%',
    //       textAlign: 'center',
    //       color: '#7F8385',
    //       lineHeight: '21px',
    //       padding: '8px 0',
    //       position: 'relative',
    //       zIndex: '99',
    //     },

    //     '& .MuiFormControlLabel-root': {
    //       marginLeft: 0,
    //       marginRight: 0,
    //       width: '50%',
    //       position: 'relative',

    //       '&:first-child': {
    //         '& .MuiButtonBase-root': {
    //           borderRadius: '8px 0 0 8px',
    //           borderRight: 0,
    //         },
    //       },
    //       '&:last-child': {
    //         '& .MuiButtonBase-root': {
    //           borderRadius: '0 8px 8px 0',
    //           borderLeft: 0,
    //         },
    //       },
    //     },
    //   },
  }
}

#scroll {
  overflow-y: scroll;
  max-height: 317px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  margin: 5px;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
  margin-block: 0.5em;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 2px 2px;
  background-color: rgba(185, 187, 189, 0.4);
}

.rc-virtual-list-scrollbar-thumb {
  background-color: rgba(185, 187, 189, 0.4) !important;
}

.ant-select-selection-overflow {
  flex-wrap: nowrap;
}

.chips_wrapper {
  display: flex;
  width: 100%;

  input {
    position: relative;
    z-index: 9;
    width: 100%;
    background: transparent;
    border: 0;
    padding: 7px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.003em;
    color: #1b1c1d;

    &.input_padding {
      padding-left: 8px;
    }
  }
}

.top_chips {
  display: flex;

  .close_btn {
    padding: 0;
    border-radius: 0;
    background: transparent;
    z-index: 99;
  }

  .selected_options {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-top: 0;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 9px 7px 10px 12px;

    &:first-child {
      margin-left: 12px;
    }

    span {
      white-space: nowrap;
      margin-right: 8px;
      color: #1b1c1d;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.task_detail {
  .ant-card-body {
    padding: 0;
  }

  .top_detail_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #dbdddf;
  }

  .tasks_detail_content {
    padding: 32px 24px;
  }

  .chip_group {
    display: flex;
    align-items: center;

    .ant-tag {
      color: #8578d7;
      padding: 5px 15px;
      background: rgba(133, 120, 215, 0.14);
      border-radius: 50px;
      border: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      margin-left: 8px;
      margin-right: 0;
      height: auto;
      width: auto;

      &.purple_tag {
        background: #8578d7;
        color: #fff;
      }

      &.orange_tag {
        background: #fa8d69;
        color: #fff;
      }

      &.isDue {
        background: #f37066;
        color: #fff;
      }
    }
  }

  .tasks_box {
    .ant-card-meta-avatar {
      // display: flex;
      align-items: center;
      margin-top: 5px;
    }

    .ant-card-meta-detail {
      .ant-card-meta-title {
        color: #7f8385;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        margin: 0;
      }

      .ant-card-meta-description {
        color: #1b1c1d;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 0;

        .ant-avatar-circle {
          position: absolute;
          // margin-top: 2px;
        }

        div {
          margin-top: 5px;
          margin-left: 25px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.edit_header {
  display: flex;
}

.task_status_drop {
  &.ant-btn {
    height: 64px;
    background: #b9bbbd;
    width: 157px;
    padding: 6px 24px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 0;

    &.not_completed {
      background: #b9bbbd;
    }

    &.in_progress {
      background: #5e95e7;
    }

    &.completed {
      background: #6bb870;
    }
  }
}

.openCircle {
  background: #fff;
  border: 1px solid #53595c;
  font-size: 11px;
  color: #53595c;
  font-weight: 400;
  width: 50px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-transform: uppercase;
}

.isOpen {
  margin-left: 0 !important;
  width: 155px;
}

.noAvatar {
  margin-left: 0 !important;
}

.error_field {
  color: #f37066;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: -8px;
}

.frequencyBtn {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 99;
}

.underline {

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #6bb870 !important;
  }

  .formControl:hover {
    border-color: #6bb870 !important;
  }
}

.recurring-btn {
  background: #f37066 !important;
  color: #fff !important;
}

.recurring-info {
  background: rgba(107, 184, 112, 0.14);
  border-radius: 8px;
  padding: 16px 26px 18px;

  .ant-typography {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.003em;
    color: #53595C;
  }

  .heading {
    font-weight: 500;
  }

  .content {
    font-weight: 400;
  }
}